<template>
    <div class="p-3">
        Kako deluje?
    </div>
</template>

<script lang="">
export default {
name: "HowItWorks",
        components: {},
        props: {},
        data() {
            return {}
        },
        mounted() {},
        methods: {},
        computed: {},
        filters: {},
        watch: {}
}

</script>

<style scoped>

</style>
